








































































import { Component, Inject, Vue } from 'vue-property-decorator';
import { ApiFacade } from '@/services/ApiFacade';
import { VForm } from '@/types/VForm';
import LoginFormHeaderLogo from '@/views/auth/components/LoginFormHeaderLogo.vue';
import { requiredValidator, emailValidator } from '@/services/defaultFormValidators';

interface LoginFormView extends Vue {
  $refs: {
    loginForm: VForm;
  };
}

@Component({
  components: { LoginFormHeaderLogo },
})
class LoginFormView extends Vue {
  email: string = '';
  password: string = '';
  showPassword: boolean = false;
  @Inject() apiFacade!: ApiFacade;
  error: Error | null = null;
  isValid: boolean = true;
  isSending: boolean = false;

  rules = {
    required: requiredValidator,
    email: emailValidator,
    min: (v: string) => v.length >= 8 || 'Минимум 8 символов',
  };

  get errorMessage() {
    return this.error?.message || '';
  }

  async submit() {
    if (!this.$refs.loginForm.validate()) {
      return;
    }
    try {
      this.isSending = true;
      this.error = null;
      const loginInfo = await this.apiFacade.checkLogin(this.email, this.password);

      if (!loginInfo?.username) {
        return;
      }

      const lastUrl: string | null = window.localStorage.getItem('voltbro.auth.last-url');
      let redirectUrl: string | null = this.$route.query.redirect_url
        ? String(this.$route.query.redirect_url)
        : null;

      if (!redirectUrl) {
        redirectUrl = lastUrl || '/';
      }

      window.localStorage.removeItem('voltbro.auth.last-url');
      window.location.href = redirectUrl;
    } catch (err) {
      this.error = err;
      console.error('Login error', err);
    } finally {
      this.isSending = false;
    }
  }
}

export default LoginFormView;
